import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class RemoveEmployeeFromTenantDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Employee id', required: true })
  employeeId!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Tenant id', required: true })
  tenantId!: string;
}

export const RemoveEmployeeFromTenantSchema = generateClassValidationObject(RemoveEmployeeFromTenantDto);
