import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { arrayQueryString } from '@assemblio/shared/helpers';
import { FolderContentSortProps } from '@assemblio/type/folder';
import * as Joi from 'joi';
import { QueryOrder } from '@assemblio/type/common';

export class FolderContentQueryDto {
  @ApiProperty({
    description: 'The content owners separated by "," separator',
    required: false,
    isArray: true,
    exploded: false,
    format: 'form',
    type: [String],
  })
  @joiSchemaProperty(arrayQueryString('uuid'))
  owners?: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(QueryOrder))
      .optional()
  )
  @ApiProperty({
    description: 'order type',
    required: false,
    enum: QueryOrder,
  })
  orderType?: QueryOrder;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(FolderContentSortProps))
      .optional()
  )
  @ApiProperty({
    description: 'order items in the folder by',
    required: false,
    enum: FolderContentSortProps,
  })
  orderBy?: FolderContentSortProps;
}

export const FolderContentQuerySchema = generateClassValidationObject(FolderContentQueryDto);
