import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class OperationalResourceAvailabilityDto {
  @ApiProperty({
    description: 'is the operational resource number available',
    required: true,
  })
  @joiSchemaProperty(Joi.boolean().required())
  available!: boolean;
}

export const OperationalResourceAvailabilitySchema = generateClassValidationObject(OperationalResourceAvailabilityDto);
