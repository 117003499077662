import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ArtefactType, ProductArtefactSortProps } from '@assemblio/type/artefacts';
import { arrayQueryString } from '@assemblio/shared/helpers';
import { QueryOrder } from '@assemblio/type/common';

export class QueryProductArtefactDto {
  @ApiProperty({
    description: 'The artefact type',
    required: false,
  })
  @joiSchemaProperty(arrayQueryString(ArtefactType))
  type?: string;

  @ApiProperty({ description: 'The artefact name', required: false })
  @joiSchemaProperty(Joi.string())
  name?: string;

  @ApiProperty({ description: 'sort by', required: false, enum: ProductArtefactSortProps })
  @joiSchemaProperty(Joi.string().valid(...Object.keys(ProductArtefactSortProps)))
  orderBy?: ProductArtefactSortProps;

  @ApiProperty({ description: 'sort type', required: false, enum: QueryOrder })
  @joiSchemaProperty(Joi.string().valid(...Object.values(QueryOrder)))
  orderType?: QueryOrder;
}

export const QueryProductArtefactSchema = generateClassValidationObject(QueryProductArtefactDto);
