import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { ProductSortProps } from '@assemblio/type/product';
import { InstructionState } from '@assemblio/type/instruction';
import { arrayQueryString } from '@assemblio/shared/helpers';
import { QueryOrder } from '@assemblio/type/common';

export class ProductQueryDto {
  @ApiProperty({ description: 'The product name', required: false })
  @joiSchemaProperty(Joi.string())
  name?: string;

  @ApiProperty({
    description: 'The folder ids separated by "," separator',
    required: false,
    isArray: true,
    exploded: false,
    format: 'form',
    type: [String],
  })
  @joiSchemaProperty(arrayQueryString('uuid'))
  folderIds?: string;

  @ApiProperty({
    description: 'The owner ids separated by "," separator',
    required: false,
    isArray: true,
    exploded: false,
    format: 'form',
    type: [String],
  })
  @joiSchemaProperty(arrayQueryString('uuid'))
  ownerIds?: string;

  @ApiProperty({
    description: 'The project ids separated by "," separator',
    required: false,
    isArray: true,
    exploded: false,
    format: 'form',
    type: [String],
  })
  @joiSchemaProperty(arrayQueryString('uuid'))
  projectIds?: string;

  @ApiProperty({
    description: 'The content states separated by "," separator',
    required: false,
    isArray: true,
    exploded: false,
    format: 'form',
    enum: InstructionState,
    type: [InstructionState],
  })
  @joiSchemaProperty(arrayQueryString(InstructionState))
  states?: string;

  @ApiProperty({ description: 'sort by', required: false, enum: ProductSortProps })
  @joiSchemaProperty(Joi.string().valid(...Object.keys(ProductSortProps)))
  orderBy?: ProductSortProps;

  @ApiProperty({ description: 'sort type', required: false, enum: QueryOrder })
  @joiSchemaProperty(Joi.string().valid(...Object.values(QueryOrder)))
  orderType?: QueryOrder;
}

export const ProductQuerySchema = generateClassValidationObject(ProductQueryDto);
