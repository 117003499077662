import { generateClassValidationObject, joiSchemaProperty, ApiProperty } from '@assemblio/shared/decorators';
import { EmployeeScope } from '@assemblio/type/employee';
import * as Joi from 'joi';

export class EmployeeTenant {
  @ApiProperty({
    description: 'The tenant id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The name of The tenant',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;

  @ApiProperty({
    description: 'The id of The role',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  roleId!: string;

  @ApiProperty({
    description: 'The name of The role',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  roleName!: string;
}

export const EmployeeTenantSchema = generateClassValidationObject(EmployeeTenant);

export class EmployeeDto {
  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Employee Id' })
  id!: string;

  @joiSchemaProperty(
    Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  )
  @ApiProperty({ description: 'Employee Email', required: true })
  email!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'First name', required: true })
  firstName!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Last name', required: true })
  lastName!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Full name', required: true })
  fullName!: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(EmployeeScope))
      .required()
  )
  @ApiProperty({ description: 'Full name', required: true, enum: EmployeeScope })
  scope!: EmployeeScope;

  @joiSchemaProperty(Joi.array().items(EmployeeTenantSchema))
  @ApiProperty({ description: 'Tenants (only for restricted employees)', required: false })
  tenants?: EmployeeTenant[];
}

export const EmployeeSchema = generateClassValidationObject(EmployeeDto);
