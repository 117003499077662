import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { ProjectSortProps } from '@assemblio/type/project';
import * as Joi from 'joi';
import { QueryOrder } from '@assemblio/type/common';

export class PaginatedProjectQueryDto {
  @joiSchemaProperty(Joi.number().min(0))
  @ApiProperty({ description: 'current page', required: false, minimum: 0 })
  curPage?: number;

  @joiSchemaProperty(Joi.number().min(1))
  @ApiProperty({
    description: 'number of items per page',
    required: false,
    minimum: 1,
  })
  perPage?: number;

  @joiSchemaProperty(Joi.string().valid(...Object.values(ProjectSortProps)))
  @ApiProperty({
    description: 'sort prop',
    required: false,
    enum: ProjectSortProps,
  })
  sort?: ProjectSortProps;

  @joiSchemaProperty(Joi.string().valid(...Object.values(QueryOrder)))
  @ApiProperty({ description: 'order type', required: false, enum: QueryOrder })
  order?: QueryOrder;
}

export const PaginatedProjectQuerySchema = generateClassValidationObject(PaginatedProjectQueryDto);
