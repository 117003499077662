import { ApiProperty, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class EmployeeRoleDto {
  @ApiProperty({
    description: 'The role id',
    required: true,
  })
  @joiSchemaProperty(Joi.string().uuid().required())
  id!: string;

  @ApiProperty({
    description: 'The name of the role',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  name!: string;
}
