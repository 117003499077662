import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import {
  OperationalResourceMainCategory,
  OperationalResourceSecondaryCategory,
  OperationalResourceSortProps,
} from '@assemblio/type/resources';
import { arrayQueryString } from '@assemblio/shared/helpers';
import { QueryOrder } from '@assemblio/type/common';

export class QueryOperationalResourceDto {
  @ApiProperty({
    description: 'The operational resource main category',
    required: false,
  })
  @joiSchemaProperty(arrayQueryString(OperationalResourceMainCategory))
  mainCategory?: string;

  @ApiProperty({
    description: 'The operational resource secondary category',
    required: false,
  })
  @joiSchemaProperty(arrayQueryString(OperationalResourceSecondaryCategory))
  secondaryCategory?: string;

  @ApiProperty({
    description: 'The operational resource name',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  name?: string;

  @ApiProperty({
    description: 'The operational resource No.',
    required: false,
  })
  @joiSchemaProperty(Joi.string())
  resourceNo?: string;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(QueryOrder))
      .optional()
  )
  @ApiProperty({
    description: 'order type',
    required: false,
    enum: QueryOrder,
  })
  orderType?: QueryOrder;

  @joiSchemaProperty(
    Joi.string()
      .valid(...Object.values(OperationalResourceSortProps))
      .optional()
  )
  @ApiProperty({
    description: 'order items in the project by',
    required: false,
    enum: OperationalResourceSortProps,
  })
  orderBy?: OperationalResourceSortProps;
}

export const QueryOperationalResourceSchema = generateClassValidationObject(QueryOperationalResourceDto);
