import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class OperationalResourceNoParamDto {
  @ApiProperty({
    description: 'The operational resource Number',
    required: true,
  })
  @joiSchemaProperty(Joi.string().required())
  no!: string;
}

export const OperationalResourceNoParamSchema = generateClassValidationObject(OperationalResourceNoParamDto);
