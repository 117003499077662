import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';
import { AddEmployeeTenantDto, AddEmployeeTenantSchema } from './add-tenant.dto';

export class CreateEmployeeDto {
  @joiSchemaProperty(
    Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  )
  @ApiProperty({ description: 'User Email', required: true })
  email!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'First name', required: true })
  firstName!: string;

  @joiSchemaProperty(Joi.string().required())
  @ApiProperty({ description: 'Last name', required: true })
  lastName!: string;

  @joiSchemaProperty(Joi.string().min(8).required())
  @ApiProperty({ description: 'User password', required: true })
  password!: string;

  @joiSchemaProperty(Joi.array().items(AddEmployeeTenantSchema))
  @ApiProperty({ description: 'User password', required: false, type: [AddEmployeeTenantDto] })
  tenants?: AddEmployeeTenantDto[];
}

export const CreateEmployeeSchema = generateClassValidationObject(CreateEmployeeDto);
