import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { EmployeeScope } from '@assemblio/type/employee';
import * as Joi from 'joi';

export class QueryEmployeeDto {
  @joiSchemaProperty(Joi.string().uuid())
  @ApiProperty({ description: 'Tenant id', required: false })
  tenantId?: string;

  @joiSchemaProperty(Joi.string().uuid())
  @ApiProperty({ description: 'The employee role id', required: false })
  roleId?: string;

  @joiSchemaProperty(Joi.string().valid(...Object.values(EmployeeScope)))
  @ApiProperty({ description: 'The employee scope', required: false, enum: EmployeeScope })
  scope?: EmployeeScope;

  @joiSchemaProperty(Joi.number().min(0).optional())
  @ApiProperty({
    description: 'Page no for pagination, used only with "itemsPerPage"',
    type: Number,
    required: false,
  })
  page?: number;

  @joiSchemaProperty(Joi.number().min(1).optional())
  @ApiProperty({
    description: 'Number of items per page for pagination',
    type: Number,
    required: false,
  })
  itemsPerPage?: number;
}

export const QueryEmployeeSchema = generateClassValidationObject(QueryEmployeeDto);
