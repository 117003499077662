import { ApiProperty, generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

export class AddEmployeeTenantDto {
  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'Tenant id', required: true })
  tenantId!: string;

  @joiSchemaProperty(Joi.string().uuid().required())
  @ApiProperty({ description: 'The employee role id', required: true })
  roleId!: string;
}

export const AddEmployeeTenantSchema = generateClassValidationObject(AddEmployeeTenantDto);
