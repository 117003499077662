import { ModelController, StepController, useUIStore } from '@assemblio/frontend/stores';
import { Button, Divider, Text, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { ContextMenu, ContextMenuProps } from './ContextMenu';
import { PartExIncludeButton } from './PartExIncludeButton';

export const PartContextMenu = (props: ContextMenuProps) => {
  const modelContext = useUIStore((state) => state.partContext);
  const selectedStep = useUIStore((state) => state.selectedStep);
  const isEditable = useUIStore((state) => state.editable);
  const [partIsInStep, setPartIsInStep] = useState(false);

  const modelName = modelContext !== undefined ? ModelController.getPartNameOverrideByGltfIndex(modelContext) : '';
  useEffect(() => {
    if (modelContext !== undefined && selectedStep) {
      const steps = StepController.getStepsByGltfIndex(modelContext);
      const containingStep = steps.find((step) => step.id === selectedStep.id);
      setPartIsInStep(containingStep !== undefined);
    }
  }, [modelContext, selectedStep]);

  const canModify =
    isEditable &&
    modelContext !== undefined &&
    !ModelController.isPartExcluded(modelContext) &&
    selectedStep &&
    StepController.canModifyStep(modelContext, selectedStep.id);

  return (
    <ContextMenu opened={props.opened} onClose={props.onClose}>
      <Text size="sm">{modelName}</Text>
      <>
        <Divider my="sm" />
        <Button.Group orientation="vertical">
          {selectedStep && !partIsInStep && (
            <Tooltip
              label={
                canModify
                  ? 'Add the part to the selected step'
                  : 'Part cannot be added, because it is used in subsequent steps or excluded'
              }
              position="bottom"
              color={!canModify ? 'red' : ''}
            >
              <Button
                variant="default"
                disabled={!canModify}
                style={{ marginBottom: '5px' }}
                onClick={() => {
                  if (modelContext !== undefined) {
                    StepController.addPartToStep(modelContext, selectedStep.id);
                    props.onClose && props.onClose();
                  }
                }}
              >
                Add Part To Step
              </Button>
            </Tooltip>
          )}
          {selectedStep && partIsInStep && (
            <Tooltip
              label={
                canModify
                  ? 'Remove the part from the selected step'
                  : 'Part cannot be removed, because it is used in subsequent steps'
              }
              position="bottom"
              color={!canModify ? 'red' : ''}
            >
              <Button
                variant="default"
                disabled={!canModify}
                style={{ marginBottom: '5px' }}
                onClick={() => {
                  if (modelContext !== undefined) {
                    StepController.removePartFromStep(modelContext, selectedStep.id);
                    props.onClose && props.onClose();
                  }
                }}
              >
                Remove Part From Step
              </Button>
            </Tooltip>
          )}
          {modelContext !== undefined && (
            <PartExIncludeButton modelContext={modelContext} onClose={props.onClose} isEditable={isEditable} />
          )}
        </Button.Group>
      </>
    </ContextMenu>
  );
};
