import { generateClassValidationObject, joiSchemaProperty } from '@assemblio/shared/decorators';
import { UserSortProps } from '@assemblio/type/user';
import { ApiProperty } from '@assemblio/shared/decorators';
import * as Joi from 'joi';

import { QueryOrder } from '@assemblio/type/common';

export class UserQueryDto {
  @joiSchemaProperty(Joi.number().min(0))
  @ApiProperty({ description: 'current page', required: false, minimum: 0 })
  curPage?: number;

  @joiSchemaProperty(Joi.number().min(1))
  @ApiProperty({
    description: 'number of items per page',
    required: false,
    minimum: 1,
  })
  perPage?: number;

  @joiSchemaProperty(Joi.string())
  @ApiProperty({
    description: 'query string to the items to search by',
    required: false,
  })
  searchBy?: string;

  @joiSchemaProperty(Joi.string())
  @ApiProperty({
    description: 'query string to the items to exclude',
    required: false,
  })
  exclude?: string;

  @joiSchemaProperty(Joi.string().valid(...Object.values(UserSortProps)))
  @ApiProperty({
    description: 'sort prop',
    required: false,
    enum: UserSortProps,
  })
  sort?: UserSortProps;

  @joiSchemaProperty(Joi.string().valid(...Object.values(QueryOrder)))
  @ApiProperty({ description: 'order type', required: false, enum: QueryOrder })
  order?: QueryOrder;
}

export const UserQuerySchema = generateClassValidationObject(UserQueryDto);
